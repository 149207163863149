import React, { useState } from "react";
import { Button, IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { FormContainer, StyledTextField } from "./Styled";
import { useProfileContext } from "../../contexts/profileContext";

const RegisterForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const profile = useProfileContext();
  const register = profile?.register;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await register({ firstName, lastName, email, password });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Typography variant="h2">Nouveau client ?</Typography>
      <StyledTextField
        required
        value={firstName}
        label="Prenom"
        variant="outlined"
        color="primary"
        onChange={(e) => setFirstName(e.target.value)}
      />
      <StyledTextField
        required
        value={lastName}
        label="Nom"
        variant="outlined"
        color="primary"
        onChange={(e) => setLastName(e.target.value)}
      />
      <StyledTextField
        required
        value={email}
        label="Email"
        variant="outlined"
        color="primary"
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        inputMode="email"
      />
      <StyledTextField
        required
        type={showPassword ? "text" : "password"}
        value={password}
        label="Mot de passe"
        variant="outlined"
        color="primary"
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? (
                  <Visibility color="secondary" />
                ) : (
                  <VisibilityOff color="secondary" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button variant="contained" color="primary" type="submit">
        Créer un compte
      </Button>
    </FormContainer>
  );
};

export default RegisterForm;
