import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormContainer = styled("form")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 20px 100px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  @media (max-width: 600px) {
    padding: 24px 16px;
    width: 100%;
    height: auto;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 300px;
  @media (max-width: 600px) {
    width: 100%;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: grey;
  }
`;
