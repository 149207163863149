import React, { useState } from 'react'
import { Button, IconButton, InputAdornment, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { FormContainer, StyledTextField } from './Styled'
import { useProfileContext } from '../../contexts/profileContext'
import { useAppContext } from '../../contexts/appContext'
import { forgotPassword } from '../../helpers/api/user'

const LoginForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const profile = useProfileContext()
  const login = profile?.login

  const [invalid, setInvalid] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState(
    'Veuillez saisir votre email'
  )
  const showSnackbar = useAppContext()?.showSnackbar

  const handleSubmit = async e => {
    e.preventDefault()
    await login({ email, password })
  }

  const handleForgotPassword = async () => {
    const regex = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
    if (email && regex.test(email)) {
      setInvalid(false)
      try {
        await forgotPassword(email)
        showSnackbar(
          'success',
          'Vous allez recevoir un email pour réinitialiser votre mot de passe'
        )
      } catch (e) {
        showSnackbar('error', 'Un problème est survenu, veuillez réessayer.')
      }
    } else {
      setInvalid(true)
      if (email) setInvalidMessage("Votre email n'est pas valide")
      else setInvalidMessage('Veuillez saisir votre email')
    }
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Typography variant="h2">Déjà client ?</Typography>
      <StyledTextField
        required
        value={email}
        label="Email"
        variant="outlined"
        InputProps={{
          type: 'email'
        }}
        error={invalid}
        helperText={invalid && invalidMessage}
        color="primary"
        onChange={e => setEmail(e.target.value)}
        type="email"
        inputMode="email"
      />
      <StyledTextField
        required
        type={showPassword ? 'text' : 'password'}
        value={password}
        label="Mot de passe"
        variant="outlined"
        color="primary"
        onChange={e => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? (
                  <Visibility color="secondary" />
                ) : (
                  <VisibilityOff color="secondary" />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Button variant="contained" color="primary" type="submit">
        Se connecter
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleForgotPassword}
      >
        Mot de passe oublié
      </Button>
    </FormContainer>
  )
}

export default LoginForm
