import React from 'react'
import { Grid } from '@mui/material'

import SEO from '../components/common/seo'
import RegisterForm from '../components/RegisterForm'
import LoginForm from '../components/LoginForm'
import { SubContainer } from '../components/common/Styled'

const Login = () => {
  return (
    <>
      <SEO title="Connexion" />
      <SubContainer>
        <Grid container justifyContent="space-evenly" flexWrap="wrap" gap={4}>
          <RegisterForm />
          <LoginForm />
        </Grid>
      </SubContainer>
    </>
  )
}

export default Login
